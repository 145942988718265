<template>
    <div class="app-container" style="box-shadow: rgb(32 44 58 / 19%) 0px 0px 0px 1px;">
        <div style="background-color: #ffffff;">
            <div id="report-map-container" :style="{ height: pageWH.mapContainerHeight + 'px' }"></div>
        </div>
    </div>
</template>
 
<script>
import AMapLoader from '@amap/amap-jsapi-loader';
import { shallowRef } from '@vue/reactivity';
import {ref} from "vue"; 

export default{
        components: { 
        },
        methods: {
            initMap() {
                window._AMapSecurityConfig = {
                    securityJsCode: 'c191fcc4848a43e58387a8b0c2918d81',
                }
                AMapLoader.load({
                    key:"ade4bf3a7cc9862c2f6f051ebbe636c7", // 申请好的Web端开发者Key，首次调用 load 时必填
                    version:"2.0", // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
                    // plugins:[''], // 需要使用的的插件列表，如比例尺'AMap.Scale'等
                }).then((AMap)=>{
                    this.AMAP = AMap
                    this.map = new AMap.Map("report-map-container",{  //设置地图容器id
                        WebGLParams:{ 
                            preserveDrawingBuffer:true 
                        },
                        viewMode:"3D",    //是否为3D地图模式
                        zoom: 12,           //初始化地图级别
                        // features: ['road'],
                        // showLabel: false, // 不显示地图文字标记
                        mapStyle: 'amap://styles/d2306de19143c83b37e262e8802ac3e9',
                        center: [113.512416, 22.618379]  // 以飞机数据为中心 // [113.808299,34.791787], //初始化地图中心点位置
                    });

                    const map = this.map
                    const self = this

                    // 添加插件
                    AMap.plugin(["AMap.ToolBar", "AMap.Scale", "AMap.HawkEye","AMap.Geolocation","AMap.MapType","AMap.MouseTool"], function () {
                        // 添加地图插件
                        //  map.addControl(new AMap.ToolBar()); // 工具条控件;范围选择控件
                        map.addControl(new AMap.Scale()); // 显示当前地图中心的比例尺
                        map.addControl(new AMap.HawkEye({visible: false})); // 显示缩略图
                        map.addControl(new AMap.Geolocation()); // 定位当前位置
                        // map.addControl(new AMap.MapType()); // 实现默认图层与卫星图,实时交通图层之间切换 切换卫星地图的按钮工具箱
                        map.setMapStyle('amap://styles/d2306de19143c83b37e262e8802ac3e9')
                        // map.setFeatures(['road','point'])  //多个种类要素显示
                    }); 

                    this.map.on("zoomchange",function(){
                        // 清除地图覆盖物, 重新绘制
                        map.clearMap();
                        // map.getZoom()
                        self.refreshAircraftInfo()

                        self.zoomLevel = map.getZoom();
                        console.log("当前地图缩放级别：" + self.zoomLevel);
                        if (self.zoomLevel > 17) {
                            self.zoomLevel = self.zoomLevel * 8
                        }
                    })

                    this.initMapOk = true
                })
            },
            refreshAircraftInfo () {
                // 没有指定当前飞机的，不绘制航线; 不是航线任务的，也不绘制航线
                this.drawLines(this.AMAP,  this.taskLinePath)
                this.drawFlightTracks(this.AMAP,  this.taskFlightTracks)
                // this.aircraftStatusCache 应该用本地Storage
                this.drawMarkers(this.AMAP, this.aircraftStatusCache)
            },
            drawLines(AMap, taskPath) {
                if(this.currTaskType !== 0) {
                     // 只根据是否有航线决定绘制
                   // return
                }
                if (taskPath == null || taskPath.length == 0) {
                    return
                }
                for (let indx in taskPath) {
                    var startPoint = new AMap.Circle({
                        center: [taskPath[indx][0], taskPath[indx][1]], // 起点坐标
                        radius: 16 * 5 / this.zoomLevel, // 圆半径
                        strokeColor: '#ACACAC', // 圆边框颜色
                        strokeWeight: 16, // 圆边框宽度
                        fillColor: '#ACACAC', // 圆填充颜色
                        fillOpacity: 1, // 圆填充透明度
                        zIndex: 500,
                    });
                    startPoint.setMap(this.map); // 将起点圆添加到地图上

                    var fontendPoint = new AMap.Circle({
                        center: [taskPath[indx][0], taskPath[indx][1]], // 起点坐标
                        radius: 10 * 5 / this.zoomLevel, // 圆半径
                        strokeColor: '#797979', // 圆边框颜色
                        strokeWeight: 10, // 圆边框宽度
                        fillColor: '#797979', // 圆填充颜色
                        fillOpacity: 1, // 圆填充透明度
                        zIndex: 600,
                    });
                    fontendPoint.setMap(this.map); // 将起点圆添加到地图上

                    var order = parseInt(indx) + 1;
                    // 如果位置闭环重叠，最后一个点和最开始一个点重合，会造成文字重影
                    var label = new AMap.Marker({
                        position: [taskPath[indx][0], taskPath[indx][1]], // 标记的位置和圆的中心一致
                        content: '<div style="color: white; font-size: 8px;">' + order + '</div>', // 标记内容为 indx 的值
                        offset: new AMap.Pixel(-3, -4), // 将标记的指向向上偏移一些，避免重叠
                        zIndex: 700,
                    });
                    label.setMap(this.map);
                }
                const poly_line_draw = new AMap.Polyline({
                    path: taskPath,
                    isOutline: true,
                    outlineColor: "#ffeeff",
                    borderWeight: 1,
                    strokeColor: "#C6C6C6",
                    strokeOpacity: 1,
                    strokeWeight: 2,
                    // 折线样式还支持 'dashed'
                    strokeStyle: "solid",
                    // strokeStyle是dashed时有效
                    // strokeDasharray: [10, 5],
                    lineJoin: "round",
                    lineCap: "round",
                    zIndex: 450,
                });
                this.map.add([poly_line_draw]);
            },
            drawMarkers(AMap, aircraftPosition) {
                if (aircraftPosition == null || aircraftPosition.length < 2) {
                    return
                }

                // 先移除
                if (this.circlePoint != null) {
                    this.map.remove(this.circlePoint)
                }
                this.circlePoint = new AMap.Circle({
                        center: [aircraftPosition[0], aircraftPosition[1]], // 起点坐标
                        radius: 12 * 5 / this.zoomLevel, // 圆半径
                        strokeColor: '#000000', // 圆边框颜色   //   28E7FE 是 00ACCD的亮色
                        strokeWeight: 30, // 圆边框宽度
                        fillColor: '#000000', // 圆填充颜色
                        fillOpacity: 0.02, // 圆填充透明度
                        zIndex: 850,
                });
                this.circlePoint.setMap(this.map); // 将起点圆添加到地图上

                const icon_Amap = new AMap.Icon({
                    size: new AMap.Size(24, 20),    // 图标尺寸
                    image: require('@/assets/device_monitor_small.png'),  // Icon的图像 require('@/icons/plane/multirotor_small.png')
                    imageOffset: new AMap.Pixel(-12, -10),  // 图像相对展示区域的偏移量，适于雪碧图等
                    imageSize: new AMap.Size(24, 20)   // 根据所设置的大小拉伸或压缩图片
                });

                /*
                const icon_Amap = new AMap.Icon({
                    size: new AMap.Size(35, 35),    // 图标尺寸
                    image: require('@/assets/device_monitor_small.png'),  // Icon的图像 require('@/icons/plane/multirotor_small.png')
                    imageOffset: new AMap.Pixel(-15, -15),  // 图像相对展示区域的偏移量，适于雪碧图等
                    imageSize: new AMap.Size(35, 35)   // 根据所设置的大小拉伸或压缩图片
                });*/

                console.log("aircraftPosition = " + aircraftPosition)
                // 先移除
                if (this.planeMark != null) {
                    this.map.remove(this.planeMark)
                }
                this.planeMark = new AMap.Marker({
                    position: new AMap.LngLat(aircraftPosition[0], aircraftPosition[1]), 
                    icon: icon_Amap,
                    title: '',   // 提示信息
                    zoom: this.map.getZoom(),
                    zIndex: 800,
                })

                /*
                let taskTypeStr = taskType == 0 ? "航线任务" : "非航线任务"
                this.planeMark.on("mouseover",()=>{
                        planeMark.setLabel({
                        direction:'top',
                        offset: new AMap.Pixel(0, 0),  //设置文本标注偏移量
                        content: `<div style='backgroun:white; padding:10px; font-size:16px;font-weight:600'><span style='margin-right:20px;color:black'>` +  this.currMaterialId + `</span><span style='color:blue'>` + taskTypeStr + `</span></div>`, //设置文本标注内容
                    });
                })
                planeMark.on("mouseout",()=>{
                    this.planeMark.setLabel(null);
                }) 
                */

                this.map.add(this.planeMark)
            },
            drawFlightTracks(AMap, tracks) {
                if (tracks == null || tracks.length == 0) {
                    return
                }
                let trackPath = []
                for (let indx in tracks) {
                    var trackPoint = new AMap.Circle({
                        center: [tracks[indx].lon, tracks[indx].lat], // 起点坐标
                        radius: 8 * 5 / this.zoomLevel, // 圆半径
                        strokeColor: '#197979', // 圆边框颜色
                        strokeWeight: 10, // 圆边框宽度
                        fillColor: '#A1ACAC', // 圆填充颜色
                        fillOpacity: 1, // 圆填充透明度
                        zIndex: 500,
                    });
                    // trackPoint.setMap(this.map); // 将起点圆添加到地图上
                    trackPath.push([tracks[indx].lon, tracks[indx].lat])
                    this.initPosition = [tracks[indx].lon, tracks[indx].lat]
                }

                if (this.initPosition != null && this.initPosition.length > 1 && this.isInit) {
                    this.map.setCenter(this.initPosition);
                    this.isInit = false
                }
                
                if (this.track_line_draw != null) {
                    this.map.remove([this.track_line_draw]);
                }
                this.track_line_draw = new AMap.Polyline({
                    path: trackPath,
                    isOutline: true,
                    outlineColor: "#28E7FE",
                    borderWeight: 1,
                    strokeColor: "#00accd",
                    strokeOpacity: 1,
                    strokeWeight: 3,
                    // 折线样式还支持 'dashed'
                    strokeStyle: "solid",
                    // strokeStyle是dashed时有效
                    // strokeDasharray: [10, 5],
                    lineJoin: "round",
                    lineCap: "round",
                    zIndex: 450,
                });
                this.map.add([this.track_line_draw]);
            },
            refreshCurrAircraftTaskPath (currTaskLineId) {
                this.$store.dispatch('flightTask/queryTaskLineInfo', {
                    taskLineId: currTaskLineId
                }).then((data) => {
                    this.loading = false
                    let linePointList = data.linePoints
                    this.activities = []
                    this.taskLinePath = []
                    for(let inx in linePointList) {
                        this.activities.push({
                            content: inx == 0 ? "起点" : "途经点",
                            destinationItem: linePointList[inx].name,
                            timestamp: '('+  linePointList[inx].longitude  + ', ' + linePointList[inx].latitude + ')',
                        })
                        this.taskLinePath.push([linePointList[inx].longitude, linePointList[inx].latitude])
                    }

                    setTimeout(() => {
                        this.drawLines(this.AMAP,  this.taskLinePath)
                    }, 200);
                }).catch((e) => {
                    this.loading = false
                })
            },
            refreshFlightTaskTracks (cTaskCode) {
                this.$store.dispatch('flightTask/queryTaskTracksList', {
                    taskCode: cTaskCode,
                    sn: this.currMaterialCode
                }).then((data) => {
                    this.loading = false
                    this.taskFlightTracks = data
                    setTimeout(() => {
                        this.drawFlightTracks(this.AMAP,  this.taskFlightTracks)
                        this.refreshTaskLineAllStatus(this.taskFlightTracks)
                    }, 200);
                }).catch((e) => {
                    this.loading = false
                })
            },
            drawAircraftTaskLine (taskLineId, taskCode, refreshTaskLineAllStatusCallback, nowTaskType, materialCode) {
                // console.log("taskLineId = " + taskLineId);
                // console.log("taskId = " + taskCode);
                this.currTaskLineId = taskLineId
                this.currTaskCode = taskCode
                this.currMaterialCode = materialCode
                this.currTaskType = nowTaskType
                this.refreshTaskLineAllStatus = refreshTaskLineAllStatusCallback
            },
            drawAircraftStatus (statusData){
                this.aircraftStatusCache = [statusData.lon, statusData.lat]
                this.drawMarkers(this.AMAP, this.aircraftStatusCache)
                this.taskFlightTracks.push({
                    lon: this.aircraftStatusCache[0],
                    lat: this.aircraftStatusCache[1]
                })
                this.drawFlightTracks(this.AMAP,  this.taskFlightTracks)
            },
            parentDrawAircraftStatus (statusData){
                if (this.initMapOk) {
                    this.drawAircraftStatus(statusData)
                } else {
                    setTimeout(() => {
                        this.parentDrawAircraftStatus(statusData)
                    }, 200);
                }
            },
            initPathTracks() {
                if (this.initMapOk && this.currTaskLineId != null && this.currTaskLineId != '') {
                    this.refreshCurrAircraftTaskPath(this.currTaskLineId)
                    this.refreshFlightTaskTracks(this.currTaskCode) 
                } else {
                    setTimeout(() => {
                        this.initPathTracks()
                    }, 200);
                }
             },
            initPage() {
                var browerWidth = window.innerWidth; //浏览器可视宽度
                var browerHeight = window.innerHeight; //浏览器可视宽度
                if (browerHeight < 739) {
                    browerHeight = 739
                } 
                this.pageWH.mapContainerHeight =  (browerHeight - 739 ) + 488
            }
        },
        data() {
            const map = {}
            const planeMark = null
            const circlePoint = null
            const track_line_draw = null
            const AMAP = {}
            const taskFlightTracks = []
            const aircraftStatusCache = []
            const realTimeInfo = {}
            const taskLinePath = ref([]);
            const currTaskLineId = ""
            const currTaskCode = ""
            const currAircraftId = ""
            return { 
                map,
                currTaskType: 0,
                initMapOk: false,
                planeMark,
                circlePoint,
                track_line_draw,
                taskLinePath,
                currTaskLineId,
                currTaskCode,
                currMaterialCode: "",
                taskFlightTracks,
                aircraftStatusCache,
                currAircraftId,
                realTimeInfo,
                zoomLevel: 12,
                initPosition: [113.512416, 22.618379],
                isInit: false,
                pageWH: {},
                AMAP,
                refreshTaskLineAllStatus: null
            };
        },
        mounted: function() { 
            this.isInit = true
            this.initMap()
            this.$nextTick(() => {
                this.initPathTracks()                
            })
            this.initPage()
            window.addEventListener('resize', this.initPage)
        },
        beforeDestroy() {
            window.removeEventListener('resize', this.initPage)
        }
    };
</script>
 
<style>
#report-map-container {
    padding:0px;
    margin: 0px;
    width: 100%;
   /*  min-height: 710px; 笔记本630 */
}

/* 高德的logo和copyright */
.amap-logo{
    display: none!important;
}

.amap-copyright{
    visibility:hidden!important;
}

.amap-icon {
    /* 不这样，图标不出来 */
    position: initial !important;  
}

/*
.el-timeline-item__node {
    background-color: #02c4e7;   航线点颜色，但按要求，应该是每个点不一样 
}
*/

.el-timeline-item__node--normal {
    left: 1px;   /* 点小偏移 */
    width: 8px;
    height: 8px;
}
</style>