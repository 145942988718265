import { getFaultCodeList, updateFaultSetting, addFaultCodeSetting, deleteFaultSetting } from '@/api/faultCode'
import { getToken, getUserInfo } from '@/utils/auth'
import { resetRouter } from '@/router'

const getDefaultState = () => {
  return {
    token: getToken(),
    userInfo: getUserInfo(),
    name: '',
    avatar: ''
  }
}

const state = getDefaultState()

const mutations = {
  RESET_STATE: (state) => {
    Object.assign(state, getDefaultState())
  }
}

const actions = {
  // user login
  queryFaultCodeList({ commit }, pageParam) {
    const { pageSize, pageIndex, searchValue, module, type, level, project } = pageParam
    return new Promise((resolve, reject) => {
        getFaultCodeList({ pageSize: pageSize, pageIndex: pageIndex, searchName: searchValue, module:module, type:type, level:level, project: project }).then(response => {
        const data = response
        resolve(data)
      }).catch(error => {
        reject(error)
      })
    })
  },
 
  createFaultCodeSetting({ commit }, param) {
    const { responsiblePerson,  verifier, project, enableLog, enableTicket, priority, severity, faultCode } = param
    return new Promise((resolve, reject) => {
        addFaultCodeSetting({ responsiblePerson: responsiblePerson, verifier: verifier, project: project, enableLog: enableLog, enableTicket: enableTicket, priority: priority, severity: severity, faultCode: faultCode }).then(response => {
        const data = response
        resolve(data)
      }).catch(error => {
        reject(error)
      })
    })
  },

  modFaultCodeSetting({ commit }, param) {
    const { id, responsiblePerson,  verifier, project, enableLog, enableTicket, priority, severity, faultCode } = param
    return new Promise((resolve, reject) => {
        updateFaultSetting({ id: id, responsiblePerson: responsiblePerson, verifier: verifier, project: project, enableLog: enableLog, enableTicket: enableTicket, priority: priority, severity: severity, faultCode: faultCode }).then(response => {
        const data = response
        resolve(data)
      }).catch(error => {
        reject(error)
      })
    })
  },

  delFaultCodeSetting({ commit }, param) {
    const { id } = param
    return new Promise((resolve, reject) => {
      deleteFaultSetting({ id: id }).then(response => {
        const data = response
        resolve(data)
      }).catch(error => {
        reject(error)
      })
    })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}

