<template>
  <div class="app_container" id="appcontainer">
    <div class="common-layout">
      <el-container>
        <el-aside width=“auto” class="aside">
          <MenuView />
          <div class="aside-footer">
              Copyright© 2023-2024 BoundaryAI
          </div>
        </el-aside>

        <el-container>
          <el-header class="header">
            <div class="left-bread-crumb" style="font-weight: 600 !important;">
              <el-breadcrumb separator="/">
                <el-breadcrumb-item v-for="(item, index) in breadcrumbList" :key="index">
                  <router-link :to="{ path: item.path, query: item.query}">{{ item.title  }}</router-link>
                </el-breadcrumb-item>
              </el-breadcrumb>
            </div>
            <div class="center-placeholder"></div>
            <div class="right-down-menu">
              <el-dropdown @command="handleCommand" style="color:black" ref="dropdown">
                <span class="avatar-wrapper">
                  <img class="user-avatar" src="@/icons/photo_color_small.png"  @click="handleDropdownClick"/>
              </span>
                <template #dropdown>
                  <el-dropdown-menu>
                    <el-dropdown-item command="modPass"><span style="display:block;">个人中心</span></el-dropdown-item>
                    <el-dropdown-item command="loginOut"><span style="display:block;">登出</span></el-dropdown-item>
                  </el-dropdown-menu>
                </template>
              </el-dropdown>
              <span class="el-dropdown-link" style="padding-left: 20px;">
                  {{ userInfo.userName }}
                </span>
            </div>
          </el-header>
          <el-main class="main scrollable-content" id="router-content">
            <router-view />
          </el-main>
          <el-footer style="text-align: center; padding: 0px 0px">
          </el-footer>
        </el-container>

      </el-container>
    </div>
  </div>

  <el-dialog v-model="dialogLogoutVisible" title="退出系统" width="520px">
        <div style="color: #00ACCD;  font-size: 18px">是否要退出系统？</div>
        <template #footer>
            <span class="dialog-footer">
                <el-button @click="dialogLogoutVisible = false">取消</el-button>
                <el-button type="primary" @click="handleLoginOut"  color="#00ACCD">确定</el-button>
            </span>
        </template>
    </el-dialog>
</template>
 
<script>
import MenuView from './MenuView.vue'
import { CaretBottom } from '@element-plus/icons-vue'
import storage from '@/utils/storage' 
import { getUserInfo } from '@/utils/auth'
export default {
  name: 'HomeMain',
  data() {
    const breadcrumbList = []
    const breadcrumbsParamMap = []
    const handleDropdownClick = () => {    
      // 手动触发 el-dropdown 的下拉菜单
      this.$nextTick(() => {
        // this.$refs.dropdown.doShow();
      });
    }
    

    const userInfo = {}
    return {
      dialogLogoutVisible: false,
      breadcrumbList,
      breadcrumbsParamMap,
      userInfo,
      pageWH: {},
      handleDropdownClick
    }
  },
  methods: {
    handleLoginOut() {
        this.$store.dispatch('user/logout', {
            userCode: ''
        }).then(() => {
            this.loading = false
        }).catch((error) => {
            this.loading = false
        })
        this.$router.push('/login');
    },
    handleCommand(command) {
      if (command === 'loginOut') {
        // this.$router.push('/login');
        this.dialogLogoutVisible = true
      }
      if (command === 'modPass') {
        this.$router.push({name:"PersonCenter" });
      }
    },
    initUserRights() {
      this.$store.dispatch('systemSetting/queryUserRights', {
        roleId: ""
       }).then((data) => {
          this.loading = false
          if (data == null) {
            console.log('set initUserRights =' + data)
          }
          storage.setDataCache("userRights", data, 3600*24*7)
      }).catch((error) => {
          // 由request.js里的Promise.reject调用进入该catch分支
          this.loading = false
          console.log(error)
      })
    },
    queryUserInfo(id) {
        console.log('query useraccount')
        this.dialogFormVisible = false
        this.$store.dispatch('useraccount/getaccount', {
            id: id
        }).then((data) => {
              this.userInfo.userCode = data.userCode
              this.userInfo.createTime = data.createdTime
              this.userInfo.userName = data.name
              // 隐藏用户菜单
              if (data.userCode === 'admin') {
                document.querySelector(".useraccountMenu").style.display = "flex";
              }
        }).catch((error) => {
            this.loading = false
            console.log(error)
        })
    },
    initSystemSetting() {
        this.initUserRights()
        const user = getUserInfo()
        // 隐藏菜单
        if(user.userCode == 'admin') {
          document.querySelector(".useraccountMenu").style.display = "flex";
        } else {
          document.querySelector(".useraccountMenu").style.display = "none";
        }

        this.queryUserInfo(user.id)
    },
    initBreadcrumb() {
        const matchedRoutes = this.$route.matched;
        const breadcrumbdata = matchedRoutes
            .filter(route => route.meta && route.meta.breadcrumb)
            .flatMap(route => route.meta.breadcrumb);
          // console.log(breadcrumbdata)

    
        for (let index in breadcrumbdata) {
          const path = breadcrumbdata[index].path;
          if (this.$route.path == path) {
            const query = this.$route.query;
            // const queryString = query == null ? '' : Object.keys(query).map(key => `${key}=${encodeURIComponent(query[key])}`).join('&');
            // breadcrumbdata[index].path = `${path}?${queryString}`;
            breadcrumbdata[index].query = query;
          }

          // 复制链接，直接刷新飞行报告界面的场景
          if(this.$route.path ==  "/flightreport" && path == "/materialrecord") {
              const query = this.$route.query;
              breadcrumbdata[index].query = { materialCode : query.materialCode, tasktableIndex : query.tasktableIndex, tasktableSize : query.tasktableSize }
          }
        }

        return breadcrumbdata
    },
    initPage() {
        var browerWidth = window.innerWidth; //浏览器可视宽度
        var browerHeight = window.innerHeight; //浏览器可视宽度
        this.pageWH = {
        }

        var baseWidth = 2048; //设计稿宽度
        var baseHeight = 991; //设计稿宽度
        console.log("浏览器：" + browerWidth + "-" +browerHeight)
        var zoomValue =  browerWidth / baseWidth; //缩放比例计算
        var zoomHeightValue =  browerHeight / baseHeight; //缩放比例计算
        /*
        document.getElementById("appcontainer").style.transform = "scale(" + zoomValue + "," + zoomHeightValue + ")"; //mainContainer为主容器id
        */
    }
  },
  components: {
    MenuView,
    CaretBottom
  },
  mounted: function() {
    
    this.initSystemSetting()
    this.breadcrumbList = this.initBreadcrumb()
    console.log(this.breadcrumbList)
    window.addEventListener('resize', this.initPage)
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.initPage)
  },
  computed: {

  },
  watch: {
    '$route'(to, from) {
      this.breadcrumbsParamMap[from.path] = from.query
      
      const toMatchedRoutes = to.matched;
      const breadcrumbfilter = toMatchedRoutes.filter(route => { 
          return route.meta && route.meta.breadcrumb 
        })
        const breadcrumb = breadcrumbfilter.flatMap(route => {
          return route.meta.breadcrumb
        });

        for (let inx in breadcrumb) {
          if(this.breadcrumbsParamMap[breadcrumb[inx].path]) {
            breadcrumb[inx].query = this.breadcrumbsParamMap[breadcrumb[inx].path]
          }

          // 复制链接，直接刷新飞行报告界面的场景
          if(to.path ==  "/flightreport" && breadcrumb[inx].path == "/materialrecord") {
            const query = to.query;
            breadcrumb[inx].query = {materialCode : query.materialCode, tasktableIndex : query.tasktableIndex, tasktableSize : query.tasktableSize }
          }
        }

    
      // 更新面包屑路径
      this.breadcrumbList = breadcrumb
    }
  }
}


</script>
<script setup>


</script>

<style scoped>
  /* 手动配置全局样式 */
  html,
  body,
  .app_container,
  .el-container{
    padding: 0;
    margin: 0;
     /*  height: 100vh;  应scale方案，就得去掉 */
  }

  /*  UI还原v1 */
  .app_container {
    min-width: 1440px;
    min-height: 739px;   /*  1440-900 确定的高度，2056*1440在125%下是2048-991  */
  }
  

  /* 背景颜色 */
  .aside{
    background-color: white;
    border-right: #d1dbe5 solid 1px;  /* #13e5f4 #0198b4  #4dd0e1 #0198b4*/
    padding: 10px;  /* 菜单效果 */
    /* box-shadow: 2px 1px 2px #4dd0e194;   边影效果 */
     box-shadow: 4px 0px 4px 0px rgba(138,152,180,0.22); 
    /*box-shadow: 2px 0 2px 0 rgba(138, 152, 180, .22);*/
    z-index: 999;  /*  不然阴影被遮挡 */
  }

  .main{
    background-color: #d1dbe54a  /* #F7FBFF; /* 控制背景色 #eeefee94 */  
  }


  .scrollable-content {
    height: calc(100vh - 60px); /* 高度是视口高度减去130px */
    overflow-y: auto; /* 显示滚动条 */
  }

  /* 滚动条整体 */
  .scrollable-content::-webkit-scrollbar {
    width: 8px; /* 滚动条的宽度 */
    height: 8px; /* 水平滚动条的高度 */
  }

  /* 滚动条轨道 */
  .scrollable-content::-webkit-scrollbar-track {
    background: #f1f1f1; /* 轨道背景色 */
    border-radius: 10px; /* 圆角 */
  }

  /* 滚动条滑块 */
  .scrollable-content::-webkit-scrollbar-thumb {
    background: #00accd; /* 滑块背景色 */
    border-radius: 10px; /* 圆角 */
  }

  /* 滚动条滑块在悬停时的样式 */
  .scrollable-content::-webkit-scrollbar-thumb:hover {
    background: #0097a7; /* 滑块背景色（悬停时） */
  }


  .header{
    background-color: #fff;
    padding: 5px;
    margin-left: 2px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 3px 6px 2px rgba(138,152,180,0.20);
    z-index: 99;  /* 否则阴影被遮挡 */
  }

  .left-bread-crumb,
  .right-down-menu {
    flex: 0 0 auto;
    padding: 0px 2px;
  }

  .right-down-menu {
    float: right;
    margin-right: 20px;
    font-weight: 600 !important;
  }

  .left-bread-crumb {
    float: left;
    margin-left: 10px;
  }

  .el-breadcrumb__inner, .el-breadcrumb__inner a {
    font-weight: 600!important;
}

  .center-placeholder {
    flex: 1 1 auto;
  }

  .el-footer {
    --el-footer-padding: 10px 10px;
    --el-footer-height: 45px !important;
    height: 0px;   /* 还原UI，定制为0px */
  }

  .el-menu-container:not(.el-menu--collapse) {
    width: 200px;
    min-height: 400px;
  }


  .right-menu {
    float: right;
    height: 100%;
    line-height: 50px;
    padding: 0px 12px;

    &:focus {
      outline: none;
    }

    .right-menu-item {
      display: inline-block;
      padding: 0 8px;
      height: 100%;
      font-size: 18px;
      color: #5a5e66;
      vertical-align: text-bottom;

      &.hover-effect {
        cursor: pointer;
        transition: background .3s;
      }
    }
    .avatar-wrapper {
      display: flex;
      align-items: center;
      position: relative;

      .user-avatar {
        cursor: pointer;
        width: 30px;
        height: 30px;
        margin-right: 10px;
        border-radius: 10px;
      }
    }

    /** 统一修改表格样式 */
  }

  .aside-footer {
    position: absolute;
    bottom: 5px;
    left: 20px;
    text-align: center;
    padding: 15px 10px;
    border-top: solid 1px #dedfdf;
    font-size: 10px;
    font-family: PingFang SC, PingFang SC-Regular;
    font-weight: Regular;
    color: #727d87;
    letter-spacing: 0.06px;
  }
</style>
<style lang="scss">
.user-dropdown {
  width: 220px;

  .user-info-dropdown {
    padding: 0 20px;

    p.user-name {
      display: flex;
      align-items: center;
    }

    p.user-code {
      font-size: 14px;
      color: #444;
      line-height: 20px;
      margin-top: 5px;
      margin-bottom: 18px;
    }

    span {
      display: inline-block;
      font-size: 22px;
      color: #444;
      font-weight: bold;
      max-width: 92px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    i {
      display: inline-block;
      margin-left: 10px;
      padding: 0 9px;
      height: 24px;
      line-height: 24px;
      background-color: #F1F1F0;
      border-radius: 12px;
      font-size: 14px;
      color: #444;
    }
  }
}
 
.el-dropdown-link:focus {
  outline: none;
}

</style>