<template>
    <div class="logo">
       <img v-bind:src="logoicon"  @click="toggleCollapse" />
    </div>
        <!--  :default-active="activeIndex  确定主页的菜单   -->
    <el-menu
      active-text-color="#ffd04b"
      background-color="#fff"
      class="el-menu-vertical-demo"
      :default-active="activeIndex"
      text-color="#000000"
      :collapse="isCollapse"
      :collapse-transition="false"
      popper-effect="light"
      router="true"
      @open="handleOpen"
      @close="handleClose">
          <el-menu-item index="/flightmonitor">
              <el-icon><Monitor /></el-icon>
              <template #title><div class="menu-title">设备实时分布</div></template>
          </el-menu-item>
          <el-menu-item index="/material">
              <el-icon><Operation /></el-icon>
              <template #title><div class="menu-title">设备管理</div></template>
          </el-menu-item>
          <!--el-menu-item index="/flightdata">
              <el-icon><Histogram /></el-icon>
              <template #title><div class="menu-title">飞行数据</div></template>
          </el-menu-item-->
          <el-menu-item index="/devicedata">
              <el-icon><Histogram /></el-icon>
              <template #title><div class="menu-title">统计数据分析</div></template>
          </el-menu-item>
          <el-menu-item index="/faultdata">
              <el-icon><Histogram /></el-icon>
              <template #title><div class="menu-title">故障数据分析</div></template>
          </el-menu-item>
          <el-menu-item index="/account" class="useraccountMenu">
              <el-icon><User /></el-icon>
              <template #title><div class="menu-title">账号管理</div></template>
          </el-menu-item>
          <el-menu-item index="/company">
              <el-icon><OfficeBuilding /></el-icon>
              <template #title><div class="menu-title">公司管理</div></template>
          </el-menu-item>
          <el-menu-item index="/authcode">
              <el-icon><Unlock /></el-icon>
              <template #title><div class="menu-title">授权管理</div></template>
          </el-menu-item>
          <!--el-menu-item index="/chargeperson">
              <el-icon><HelpFilled /></el-icon>
              <template #title><div class="menu-title">故障码管理</div></template>
          </el-menu-item-->
          <el-menu-item index="/faultcode">
              <el-icon><Setting /></el-icon>
              <template #title><div class="menu-title">故障码配置</div></template> 
          </el-menu-item>
          <el-menu-item index="/firmware">
            <el-icon><HelpFilled /></el-icon>
            <template #title><div class="menu-title">固件管理</div></template>
          </el-menu-item>
          <!--el-menu-item index="/firmware">
              <el-icon><HelpFilled /></el-icon>
              <template #title><div class="menu-title">固件管理</div></template>
          </el-menu-item>
          <el-sub-menu index="/firmware" id="sidebar">
              <template #title>
                <el-icon><Files /></el-icon>
                <span style=" color: #324257; font-size: 14px; font-weight: 600;">固件管理</span>
              </templat
              <--el-menu-item index="/avionicsfirmware">航电固件</el-menu-item>
              <el-menu-item index="/firmware/gcsfirmware">地面站固件</el-menu-item>
              <el-menu-item index="/firmware/toolsfirmware">工具中心固件</el-menu-item>
          </el-sub-menu-->
      </el-menu>
  </template>
   
  <script setup>
  import { ref, watchEffect  } from "vue";
  import { useRoute } from 'vue-router';
  import { getUserInfo } from '@/utils/auth'
  import {
    HelpFilled,  Menu as IconMenu, Unlock,  Operation, Monitor, Histogram, User, OfficeBuilding, Files, Setting } from "@element-plus/icons-vue";

  const isCollapse = ref(false);
  let logoicon = require('@/assets/boundaryai_small.png')
  const handleOpen = (key, keyPath) => {
    // console.log(key, keyPath);
  };
  const handleClose = (key, keyPath) => {
   // console.log(key, keyPath);
  };
  const toggleCollapse = ()  => {
    /* 不需要收缩  */
    // isCollapse.value = !isCollapse.value;
    // logoicon = isCollapse.value ? require("@/assets/logo-small.png") : require("@/assets/boundaryai_small.png")
  };

  // 根据路由，匹配菜单
  const route = useRoute();
  const menMap = {
    "/flightreport": "/material",
    "/materialrecord": "/material",
    "/authorizedapp": "/authcode",
  }
  const activeIndex = ref("/flightmonitor"); // 默认激活的菜单项
  watchEffect(() => {
    activeIndex.value = (menMap[route.path] == null) ? route.path : menMap[route.path]; // 根据当前路由更新激活的菜单项
  });
  </script>
   
  <style> 
  .el-sub-menu__title {
    height: 30px !important;
    line-height: 30px !important;
    margin-left: 25px;
  }

  .el-menu-item[data-v-795bc4d9]:hover {
    color: #00accd;
    background: #00accd00 !important;
}
  </style>
  <style scoped>
  .logo {
    border-bottom: 1px solid #E8ECEF;
    align-items: center;
    margin: auto;
    display: flex;
    justify-content: center;
    padding-bottom: 20px;
  }
  .my-label {
    background: #ffffff !important;
  }
  .my-content {
    background: var(--el-color-danger-light-9);
  }

  .el-popper.is-dark {
    color: var(--el-bg-color);
    background: #e95e0e !important; 
    border: 1px solid #14f235;
}
 
.el-menu {
    border-right: solid 0px #ee7904 !important;   /* 菜单效果 */
}

.el-menu-item [class^=el-icon] {
    margin-right: 20px;
    margin-left: 5px;
}

.el-sub-menu .el-icon {
    margin-right: 20px;
}

.el-sub-menu .el-menu {
    margin-left: 25px;
}

.el-sub-menu .el-menu-item {
    margin: 0px;
    margin-left: 45px;
    margin-top: 20px;
    margin-bottom: 20px;
}

.el-sub-menu .el-menu-item {
    width: 155px;
}

.menu-title {
    width: 56px;
    height: 20px;
    font-size: 14px;
    font-family: PingFang SC, PingFang SC-Medium;
    font-weight: Medium;
    text-align: left;
    line-height: 20px;
}


.el-menu-item {
    color: #324257;
    font-weight: 600;
    height: 36px;
    width: 160px;
    margin: 20px;
    padding-top: 18px;
    padding-bottom: 18px;
}



.el-menu-vertical-demo {
    .el-menu-item {
        &.is-active {
            color: #fffffe;
            background: #00accd !important;
            border-radius: 2px;
            box-shadow: 0px 3px 6px 0px rgba(132, 199, 228, 0.74);
        }

        &:hover {
            color: #00accd;
            background: #00accd00 !important;
        }

        &.is-active:hover {
            color: #fffffe; /* 保持 active 状态的文字颜色 */
            background: #00accd !important; /* 保持 active 状态的背景颜色 */
            /* 如果你想要加一些效果，比如深色的阴影，可以在这里加 */
            /* box-shadow: 0px 3px 6px 0px rgba(132, 199, 228, 0.74); */
        }
    }
}
</style>