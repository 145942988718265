import axios from 'axios'
import { ElMessageBox } from 'element-plus'
import router from '../router/index'
import { getToken, setToken, getRefreshToken, setRefreshToken, getUserInfo, isTokenExpireate, setTokenExpiration } from '@/utils/auth'

const baseURL = process.env.VUE_APP_BASE_API
const timeout = 1800000

const service = axios.create({
  baseURL,
  timeout,
})

const syncService = axios.create({
  baseURL,
  timeout,
})

let isRefreshing = false
let requests = []

const refreshToken = async () => {
  const refreshToken = getRefreshToken()
  const userId = getUserInfo().id

  try {
    syncService.defaults.headers['refreshToken'] = getRefreshToken()
    const response = await syncService.post('/bd-gms/user/token', { refreshToken, userId }, true)
    const { code, message, data } = response.data

    if (code === 200) {
      const { token, refresh_token, expiration_date } = data
      setToken(token)
      setRefreshToken(refresh_token)
      setTokenExpiration(expiration_date)

      return token
    } else {
      if (message === 'authority_no_login') {
        throw new Error(message || '未登录或登录已失效，请重新登录！')
      }

      if (response.code === 500 || data.data == null) {
        console.log('获取token失败，重新登录！')
        router.replace({ path: '/login' })
      }
    }
  } catch (err) {
    if ((err.response && err.response.status === 401) || (err.config && err.config.url.indexOf("/user/token") > -1 )) {
      console.log('登录状态已失效，请重新登录！')
      router.replace({ path: '/login' })  
    }
    return Promise.reject(err)
  }
}

// 请求拦截
const requestInterceptor = async config => {
  if (!config.url.includes('/user/login') && !config.url.includes('/user/token')) {
    if (isTokenExpireate()) {
      if (!isRefreshing) {
        isRefreshing = true

        try {
          const token = await refreshToken()
          config.headers.token = token
          console.log('Token refresh ok')

          requests.forEach(cb => cb(token))
          requests = []

          return config
        } catch (error) {
          console.log('Token refresh failed')
          router.replace({ path: '/login' })
          
          const cancelToken = new axios.CancelToken(cancel => cancel({
            message: '登录已过期，请重新登录！',
            type: 'error',
            duration: 500 * 1000
          }))
          throw { cancelToken, message: '登录已过期，请重新登录！' }
        } finally {
          isRefreshing = false
        }
      } else {
        return new Promise(resolve => {
          requests.push(() => {
            config.headers.token = getToken()
            resolve(config)
          })
        })
      }
    }
  }

  if (getToken() && !config.url.includes('/bd-gms/user/login')) {
    config.headers.token = getToken()
  }

  return config
}

// 请求正常
const responseInterceptor = response => {
  const { code, message, data } = response.data


  const res = response.data
  if (response.config.responseType != "blob") {
    if (code === 200) {
      console.log("res.success = " + res.success)
      return data
    } else {
      /*
      ElMessageBox({
        message: '' + message || 'Error',
        type: 'error',
        duration: 500 * 1000
      })
    */
      return Promise.reject((message || 'Error'))
    }
  } 
  else {
    // 下载
    return response
  }
}

const errorInterceptor = async error => {
  if(error.response == null) {
    if (navigator.onLine) { 
        //正常工作
        console.log('正常联网')
      } else { 
        //执行离线状态时的任务
        ElMessageBox({
          message: "无法发送请求，请检查网络！",
          type: 'error',
          duration: 5 * 1000
        })
        return
      }     
  }
  // 重新刷token
  if (error.response && error.response.status === 401 && !error.config.url.includes('/user/token') && !error.config.url.includes('/user/login')) {
    const { config } = error

    if (!isRefreshing) {
      isRefreshing = true

      try {
        const token = await refreshToken()
        config.headers.token = token
        console.log('Token refresh ok after error')

        requests.forEach(cb => cb(token))
        requests = []

        return service(config)
      } catch (err) {
       if ((err.response && err.response.status === 401) || (err.config && err.config.url.indexOf("/user/token") > -1 )) {
          console.log('抱歉，您的登录状态已失效，请重新登录！')
          router.replace({ path: '/login' })
        }

        console.log('Token refresh failed')
      } finally {
        isRefreshing = false
      }
    } else {
      return new Promise(resolve => {
        requests.push(() => {
          config.headers.token = getToken()
          resolve(service(config))
        })
      })
    }
  } else {
    if (error.config && error.config.url.includes('/user/login')) {
        if(error.response && error.response.status == 401) {
          // 非 401
          ElMessageBox({
            message: "账号或密码错误",
            type: 'error',
            duration: 5 * 1000
          })
        } else if(error.response && error.response.status == 404) {
          ElMessageBox({
            message: "系统服务不存在",
            type: 'error',
            duration: 5 * 1000
          })
        }
     } else {
        if (error.message) {
          ElMessageBox({
            message: error.message,
            type: 'error',
            duration: 5 * 1000
          })
        } else if(error.response && error.response.status == 404) {
          ElMessageBox({
            message: "系统服务不存在",
            type: 'error',
            duration: 5 * 1000
          })
        } else if(error.response && error.response.status == 500) {
          ElMessageBox({
            message: "系统服务内部错误",
            type: 'error',
            duration: 5 * 1000
          })
        }
     }
 
    return Promise.reject(error)
  }
}

service.interceptors.request.use(requestInterceptor, error => Promise.reject(error))
service.interceptors.response.use(responseInterceptor, errorInterceptor)

export default service
